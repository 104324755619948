var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        name: "fade-transition",
        mode: "out-in",
        duration: 200,
        appear: "",
        "leave-absolute": "",
      },
    },
    [
      _c("router-view", {
        key: "router-view-casino",
        attrs: { transition: "fade-transition" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }